import { FC } from 'react';
import { IconButton } from '@mui/material';
import Iconify from '../Iconify';
import { FieldWrapper, GeneralBoxWrapper, IconWrapper, ValueWrapper } from './styles';
import type { IInviteCardTooltip } from './types';

export const InviteCardTooltip: FC<IInviteCardTooltip> = ({ fields }) => (
	<GeneralBoxWrapper>
		{fields.map(({ icon, value, onCopy }) => (
			<FieldWrapper key={value}>
				<IconWrapper>
					<Iconify icon={icon} fontSize={15} color='#FFFFFF' />
				</IconWrapper>
				<ValueWrapper>
					{value}
					{!!onCopy && (
						<IconButton onClick={onCopy} sx={{ ml: '5px' }}>
							<Iconify icon='solar:copy-linear' fontSize={18} color='#D6D9DE' />
						</IconButton>
					)}
				</ValueWrapper>
			</FieldWrapper>
		))}
	</GeneralBoxWrapper>
);
