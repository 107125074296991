import { capitalCase } from 'change-case';
import { Rooms, RoomTypes } from 'src/redux/types';

const mapRoomsTypes = (types: RoomTypes) =>
	Object.keys(types)
		.map((key) => {
			const type = types[key];
			const elementName = `${key}_rooms`;

			return {
				type: 'paneldynamic',
				name: key,
				title: type.name,
				description: type.description,
				order: type.order,
				hideNumber: true,
				templateElements: [
					{
						type: 'dropdown',
						name: elementName,
						maxWidth: '60%',
						title: ' ',
						description: 'Room *',
						isRequired: true,
						choices: type.options.map((o) => ({ value: o.value, text: o.name })),
						choicesOrder: 'asc',
					},
					{
						type: 'text',
						name: `${key}_count`,
						minWidth: '150px',
						maxWidth: '20%',
						startWithNewLine: false,
						title: 'Qty *',
						titleLocation: 'hidden',
						description: 'The number of rooms of the chosen type',
						isRequired: true,
						inputType: 'number',
						min: 1,
						max: 20,
						minErrorText: 'The room count should not be less than 1',
						step: 1,
						placeholder: 'Count *',
						defaultValue: [1],
					},
					{
						type: 'text',
						name: `${key}_name`,
						minWidth: '300px',
						maxWidth: '30%',
						startWithNewLine: false,
						title: ' ',
						description: 'Name *',
						isRequired: true,
						placeholder: 'Name *',
						defaultValue: [`${capitalCase(key)} room`],
					},
				],
				noEntriesText: ' ',
				confirmDeleteText: 'Do you want to delete the room?',
				panelAddText: 'Add room',
				panelRemoveButtonLocation: 'right',
			};
		})
		.sort((a, b) => a.order - b.order);

export const propertyDetails = (rooms: Rooms) => ({
	logoWidth: '100px',
	logoHeight: '100px',
	logoPosition: 'right',
	completedHtml: "<h3>That's it! Thank you for completing the Property Details card! 🎉</h3>",
	completedBeforeHtml: '<h3>It looks like you have already answered all questions in this section 🧐.</h3>',
	loadingHtml: '<h3>Loading questions...</h3>',
	pages: [
		{
			name: 'dwelling',
			elements: [
				{
					type: 'dropdown',
					name: 'property_type',
					title: 'What type of property is it? *',
					titleLocation: 'left',
					hideNumber: true,
					isRequired: true,
					choices: ['Flat', 'Maisonette', 'Bungalow', 'House', 'Park home'],
				},
				{
					type: 'boolean',
					name: 'new_build_yn',
					title: 'Is the property a new build? *',
					titleLocation: 'left',
					hideNumber: true,
					isRequired: true,
				},
				{
					type: 'boolean',
					name: 'share_of_freehold_yn',
					title: 'Is your property a share of freehold?',
					description: 'That is, do you share ownership of the freehold title with others?',
					hideNumber: true,
					isRequired: true,
				},
				{
					type: 'text',
					name: 'level_count',
					title: 'How many levels is the property distributed on? *',
					titleLocation: 'left',
					hideNumber: true,
					isRequired: true,
					inputType: 'number',
					min: 1,
				},
				{
					type: 'text',
					name: 'room_count',
					title: 'How many rooms does the property have? *',
					titleLocation: 'left',
					description: 'Please include all rooms that would be labelled on a floor plan',
					hideNumber: true,
					isRequired: true,
					inputType: 'number',
					min: 1,
					step: 1,
				},
				{
					type: 'text',
					name: 'total_floor_area',
					title: 'Internal floor area',
					titleLocation: 'left',
					description: 'in square feet',
					descriptionLocation: 'underInput',
					hideNumber: true,
					inputType: 'number',
					autocomplete: 'name',
					min: 1,
				},
				{
					type: 'text',
					name: 'ceiling_height',
					title: 'Ceiling height',
					titleLocation: 'left',
					description: "in feet (') and inches ('')",
					descriptionLocation: 'underInput',
					hideNumber: true,
					autocomplete: 'name',
					min: 1,
					placeholder: 'e.g. 8\'4"',
				},
			],
			title: 'Dwelling',
		},
		{
			name: 'building',
			elements: [
				{
					type: 'dropdown',
					name: 'built_form',
					title: 'What is the built form of the building? *',
					titleLocation: 'left',
					hideNumber: true,
					isRequired: true,
					choices: [
						'Detached',
						'Semi-detached',
						'Mid-terrace',
						'End-terrace',
						'Enclosed Mid-terrace',
						'Enclosed End-terrace',
						'Multi-storey',
					],
				},
				{
					type: 'text',
					name: 'building_storeys',
					visibleIf: "({property_type} = 'Flat') or ({property_type} = 'Maisonette')",
					title: 'How many levels does the building have? *',
					titleLocation: 'left',
					description: 'The ground floor counts as one level',
					hideNumber: true,
					isRequired: true,
					requiredIf: "({property_type} = 'Flat') or ({property_type} = 'Maisonette')",
					inputType: 'number',
				},
				{
					type: 'text',
					name: 'building_floor',
					visibleIf: "({property_type} = 'Flat') or ({property_type} = 'Maisonette')",
					title: 'What building floor is the property situated on? *',
					titleLocation: 'left',
					description: 'For multi-level properties, specify entrance floor',
					hideNumber: true,
					isRequired: true,
					requiredIf: "({property_type} = 'Flat') or ({property_type} = 'Maisonette')",
					inputType: 'number',
				},
				{
					type: 'boolean',
					name: 'top_floor_yn',
					visibleIf: "({property_type} = 'Flat') or ({property_type} = 'Maisonette')",
					title: 'Is this the top floor of the building? *',
					titleLocation: 'left',
					hideNumber: true,
					isRequired: true,
					requiredIf: "({property_type} = 'Flat') or ({property_type} = 'Maisonette')",
				},
				{
					type: 'boolean',
					name: 'above_shop_yn',
					visibleIf: "({property_type} = 'Flat') or ({property_type} = 'Maisonette')",
					title: 'Is the property located over commercial premises? *',
					titleLocation: 'left',
					hideNumber: true,
					isRequired: true,
					requiredIf: "({property_type} = 'Flat') or ({property_type} = 'Maisonette')",
				},
			],
			title: 'Building',
		},
		{
			name: 'rooms',
			elements: [
				...mapRoomsTypes(rooms.types),
				{
					type: 'html',
					name: 'pledge',
					html: "By clicking <b>'Submit'</b>, you confirm that all information disclosed in this questionnaire is accurate to the best of your knowledge, and that you have not withheld any materially significant information regarding the property.",
				},
			],
			title: 'Rooms',
		},
	],
	showCompletedPage: false,
	showProgressBar: 'top',
	completeText: 'Submit',
	widthMode: 'static',
});
