import { useSelector } from 'react-redux';
import { selectTransactionOverview } from 'src/redux/slices/transaction';
import { useEffect, useState } from 'react';
import { Tracker } from 'src/types';
import { getTracker } from 'src/utils/firebase';
import { TransactionSummaryTable } from '../../styles';
import OldTrackerSection from './components/OldTrackerSection';

const OldTracker = () => {
	const [tracker, setTracker] = useState<Tracker | null>(null);
	const transaction = useSelector(selectTransactionOverview);

	useEffect(() => {
		const setup = async () => {
			if (!transaction.trackerId) return;

			const { data } = await getTracker(transaction.trackerId);

			setTracker(data);
		};

		setup();
	}, [transaction.trackerId]);

	if (!tracker) return null;

	return (
		<TransactionSummaryTable title='Old tracker' minWidth='1000px' sx={{ position: 'relative' }}>
			<OldTrackerSection tracker={tracker} step='sellerDueDiligence' />
			<OldTrackerSection tracker={tracker} step='listing' />
			<OldTrackerSection tracker={tracker} step='saleAgreed' />
			<OldTrackerSection tracker={tracker} step='buyerDueDiligence' />
			<OldTrackerSection tracker={tracker} step='exchange' />
			<OldTrackerSection tracker={tracker} step='completion' />
		</TransactionSummaryTable>
	);
};

export default OldTracker;
