import { Stack } from '@mui/material';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { selectTransactionOverview } from 'src/redux/slices/transaction';
import { TrackerWrapper } from './styles';
import { ITrackerStep } from '../TrackerStep/types';
import TrackerStep from '../TrackerStep';
import { TrackerStepSeparator } from '../TrackerStep/styles';

const Tracker = () => {
	const transaction = useSelector(selectTransactionOverview);

	const steps: Array<Omit<ITrackerStep, 'order'> & { key: string }> = useMemo(
		() =>
			[
				{
					...transaction.tracker.sellerDueDiligence,
					key: 'sellerDueDiligence',
					title: (
						<>
							Seller <br /> due diligence
						</>
					),
				},
				{ ...transaction.tracker.listing, key: 'listing', title: 'Listing' },
				{ ...transaction.tracker.saleAgreed, key: 'saleAgreed', title: 'Sale agreed' },
				{
					...transaction.tracker.buyerDueDiligence,
					key: 'buyerDueDiligence',
					title: (
						<>
							Buyer <br /> due diligence
						</>
					),
				},
				{ ...transaction.tracker.exchange, key: 'exchange', title: 'Exchange' },
				{ ...transaction.tracker.completion, key: 'completion', title: 'Completion' },
			].sort((a, b) => {
				if (a.key === 'completion') return 1;
				if (b.key === 'completion') return -1;

				if (a.status === 'completed' && !a.date) return b.status === 'completed' && !b.date ? 0 : -1;
				if (b.status === 'completed' && !b.date) return 1;

				if (!a.date) return 1;
				if (!b.date) return -1;

				return new Date(a.date).getTime() - new Date(b.date).getTime();
			}),
		[transaction.tracker],
	);

	return (
		<Stack flexDirection='row' m='40px 0 70px 20px'>
			<TrackerWrapper>
				{steps.map((step, index) => (
					<>
						<TrackerStep {...step} order={index + 1} />
						{index + 1 !== steps.length && <TrackerStepSeparator />}
					</>
				))}
			</TrackerWrapper>
		</Stack>
	);
};

export default Tracker;
