import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { Box } from '@mui/system';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { CircularLoader } from 'src/components/CircularLoader';
import Page from 'src/components/Page';
import { selectUser } from 'src/redux/slices/auth';
import {
	selectTransactionOverview,
	selectTransactionOverviewLoading,
	selectTransactionParticipantsField,
	selectTransactionSlice,
	setCurrentTransactionTab,
} from 'src/redux/slices/transaction';
import { dispatch } from 'src/redux/store';
import { openModal } from 'src/redux/slices/modal';
import { clearQuestionnairePermissions, getQuestionnairePermissionsThunk } from 'src/redux/slices/permissions';
import { formatBidValue } from 'src/utils/formatBid';
import { useTheme } from '@mui/material';
import { selectPropertySlice } from 'src/redux/slices/property';
import { QuestionnaireStatus, VerificationSessionStatus } from 'src/types';
import { clearDocuments } from 'src/redux/slices/documents';
import modals from 'src/constants/modals';
import { selectTransactionQuestionnaireSlice } from 'src/redux/slices/transactionQuestionnaire';
import { selectPropertyQuestionnaireSlice } from 'src/redux/slices/propertyQuestionnaire';
import FreezeTransactionModal from 'src/components/modals/transaction/FreezeTransaction';
import UnfreezeTransactionModal from 'src/components/modals/transaction/UnfreezeTransaction';
import ArchiveTransactionModal from 'src/components/modals/transaction/ArchiveTransaction';
import UnbinTransactionModal from 'src/components/modals/transaction/UnbinTransaction';
import AssignTitleModal from 'src/components/TransactionSummary/components/AssignTitle';
import SearchTitleModal from 'src/components/TransactionSummary/components/SearchTitle';
import TitleAssignmentConfirmationModal from 'src/components/TransactionSummary/components/TitleAssignmentConfirmationModal';
import ForceTitleAssignmentModal from 'src/components/TransactionSummary/components/ForceTitleAssignmentModal';
import { OuterStackStyled, TitleStackStyled, TitleStyled } from './style';
import DropPropertyModal from './modals/DropPropertyModal';
import { getTransactionTabs } from './utils/navigation';
import PropertyOnboardingFlow from '../properties/components/PropertyOnboardingFlow';
import { propertyOnboardingSteps } from '../properties/constants';
import TransactionQrCodeModal from './modals/TransactionQrCodeModal';
import TransactionOnboardingFlow from './components/TransactionOnboardingFlow';
import { OfferRejectedModal } from './modals/OfferRejectedModal';
import { OfferAcceptedModal } from './modals/OfferAcceptedModal';
import CounterOfferReceivedModal from './modals/CounterOfferReceivedModal';
import { AcceptCounterofferModal } from './modals/AcceptCounterofferModal';
import CounterOfferAcceptedModal from './modals/CounterOfferAcceptedModal';
import { ViewerOfferRejectedModal } from './modals/ViewerOfferRejectedModal';
import ViewerCounterofferReceivedModal from './modals/ViewerCounterofferReceivedModal';
import CompleteIdChecksModal from './modals/CompleteIdChecksModal';
import CompleteYourPersonalDetailsModal from './modals/CompleteYourPersonalDetailsModal';
import { RequiredAskPriceModal } from './modals/RequiredAskPriceModal';
import { getTransactionInterest } from './utils/common';
import ConfirmDetailsModal from './participants/components/ConfirmDetailsModal';
import GiftorWelcomeModal from './components/GiftorWelcomeModal';
import EnterSignatureModal from './modals/EnterSignatureModal';
import SignDeedModal from './modals/SignDeedModal';

export const MyTransaction = () => {
	const { state } = useLocation();
	const theme = useTheme();
	const user = useSelector(selectUser);
	const { property } = useSelector(selectPropertySlice);
	const { currentTab } = useSelector(selectTransactionSlice);
	const transaction = useSelector(selectTransactionOverview);
	const { data: participants } = useSelector(selectTransactionParticipantsField);
	const { fullQuestionnaire: transactionQuestionnaire } = useSelector(selectTransactionQuestionnaireSlice);
	const { fullQuestionnaire: propertyQuestionnaire } = useSelector(selectPropertyQuestionnaireSlice);
	const isLoading = useSelector(selectTransactionOverviewLoading);

	useEffect(() => {
		if (!transaction.isGiftorWelcomeEmailSkipped) {
			dispatch(openModal({ name: modals.giftorWelcome }));

			return;
		}

		if (transaction.myOffer?.isRejected && transaction.isBuyerViewer) {
			dispatch(openModal({ name: modals.offerRejected, payload: { offer: transaction.myOffer } }));

			return;
		}

		if (transaction.counterofferToMe?.isActive && transaction.isBuyerViewer) {
			const isViewedByUser = transaction.counterofferToMe.viewedBy.includes(user.uid);

			if (isViewedByUser) return;

			dispatch(openModal({ name: modals.counterOfferReceived, payload: { offer: transaction.counterofferToMe } }));

			return;
		}

		if (transaction.askPrice && transaction.isConfirmedBuyer) {
			const isAgreedByUser = transaction.askPrice.agreedBy.includes(user.uid);

			if (isAgreedByUser) return;

			dispatch(openModal({ name: modals.offerAccepted, payload: { offer: transaction.askPrice } }));

			return;
		}

		const acceptedOffer = transaction.offers.find((o) => o.fromUser === user.uid && o.isAccepted);

		if (acceptedOffer && participants?.participants) {
			const isViewedByUser = acceptedOffer.viewedBy.includes(user.uid);
			const viewer = participants.participants.viewers.find((v) => v.uid === acceptedOffer.toUser);

			if (isViewedByUser || !viewer) return;

			dispatch(openModal({ name: modals.counterOfferAccepted, payload: { user: viewer, offer: acceptedOffer } }));
		}

		const receivedOffer = transaction.offers.find((o) => o.toUser === user.uid && o.isActive);

		if (receivedOffer && participants?.participants) {
			const isViewedByUser = receivedOffer.viewedBy.includes(user.uid);
			const viewer = participants.participants.viewers.find((v) => v.uid === receivedOffer.fromUser);

			if (isViewedByUser || !viewer) return;

			dispatch(openModal({ name: modals.viewerCounterOfferReceived, payload: { user: viewer, offer: receivedOffer } }));
		}

		const rejectedOffer = transaction.offers.find((o) => o.fromUser === user.uid && o.isRejected);

		if (rejectedOffer && participants?.participants) {
			const isViewedByUser = rejectedOffer.viewedBy.includes(user.uid);
			const viewer = participants.participants.viewers.find((v) => v.uid === rejectedOffer.toUser);

			if (isViewedByUser || !viewer) return;

			dispatch(openModal({ name: modals.viewerOfferRejected, payload: { user: viewer, offer: rejectedOffer } }));
		}
	}, [
		transaction.myOffer,
		transaction.agreedOffer,
		transaction.offers,
		transaction.isBuyer,
		transaction.isBuyerViewer,
		participants?.participants,
	]);

	useEffect(() => {
		if (state?.tab) dispatch(setCurrentTransactionTab(state.tab));
	}, [state?.tab]);

	useEffect(() => {
		if (!transaction.askPrice) {
			if (user.isAdmin) return;

			const isVerified = user.verificationStatus === VerificationSessionStatus.VERIFIED;

			if (user.isAncillary && isVerified && !transaction.askPrice) {
				dispatch(openModal({ name: modals.requiredAskPrice }));

				return;
			}

			const personalDetails = transactionQuestionnaire?.personal_details?.[user.uid];

			if (
				user.isPrincipal &&
				isVerified &&
				!transaction.askPrice &&
				personalDetails?.personal?.status === QuestionnaireStatus.DONE
			) {
				dispatch(openModal({ name: modals.requiredAskPrice }));
			}
		}
	}, [participants, transaction.askPrice, user]);

	useEffect(() => {
		dispatch(getQuestionnairePermissionsThunk({ propertyId: transaction.propertyId }));

		return () => {
			dispatch(clearQuestionnairePermissions());
		};
	}, [transaction.propertyId]);

	useEffect(
		() => () => {
			dispatch(setCurrentTransactionTab('general'));
			dispatch(clearDocuments());
		},
		[],
	);

	useEffect(() => {
		if (user.verificationStatus === VerificationSessionStatus.FAILED) {
			dispatch(openModal({ name: propertyOnboardingSteps.verificationFail }));
		}
	}, [property?.latestOnboardingStep]);

	const tabs = useMemo(
		() => getTransactionTabs(transaction.isPendingParticipant, transaction.isBuyerViewer, user.isAdmin),
		[transaction.isPendingParticipant, transaction.isBuyerViewer, user.isAdmin],
	);
	const handleTabChange = (_, tab: string) => {
		dispatch(setCurrentTransactionTab(tab));
	};

	const interest = getTransactionInterest(transaction, propertyQuestionnaire);

	return (
		<Page title={transaction.name}>
			<OuterStackStyled>
				<TitleStackStyled>
					<DropPropertyModal />
					<TitleStyled>
						{transaction.name}{' '}
						{(!!transaction.askPrice || !!interest) && (
							<TitleStyled color={theme.palette.info.dark} display='inline'>
								(
								{[interest, transaction.askPrice ? `£${formatBidValue(transaction.askPrice.price)}` : null]
									.filter(Boolean)
									.join(', ')}
								)
							</TitleStyled>
						)}
					</TitleStyled>
				</TitleStackStyled>
				<Tabs
					allowScrollButtonsMobile
					variant='scrollable'
					scrollButtons='auto'
					value={currentTab}
					onChange={handleTabChange}
				>
					{tabs.map((tab) => (
						<Tab
							disableRipple
							key={tab.value}
							label={tab.label}
							icon={tab.icon}
							value={tab.value}
							disabled={transaction.isBuyerViewer && tab.value === 'transaction questions'}
						/>
					))}
				</Tabs>
			</OuterStackStyled>

			{tabs.map(({ value, component: Component }) => {
				const isMatched = value === currentTab;
				return (
					isMatched && (
						<Box key={value} sx={{ height: '100%' }}>
							{isLoading ? <CircularLoader /> : <Component propertyId={transaction.property.id} />}
						</Box>
					)
				);
			})}
			<PropertyOnboardingFlow />
			<TransactionQrCodeModal />
			<TransactionOnboardingFlow />
			<OfferRejectedModal />
			<OfferAcceptedModal />
			<CounterOfferReceivedModal />
			<CounterOfferAcceptedModal />
			<AcceptCounterofferModal />
			<ViewerCounterofferReceivedModal />
			<ViewerOfferRejectedModal />
			<CompleteIdChecksModal />
			<CompleteYourPersonalDetailsModal />
			<RequiredAskPriceModal />
			<FreezeTransactionModal />
			<UnfreezeTransactionModal />
			<ArchiveTransactionModal />
			<UnbinTransactionModal />
			<ConfirmDetailsModal />
			<GiftorWelcomeModal />
			<AssignTitleModal />
			<SearchTitleModal />
			<TitleAssignmentConfirmationModal />
			<ForceTitleAssignmentModal />
			<EnterSignatureModal />
			<SignDeedModal />
		</Page>
	);
};
