/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FC } from 'react';
import { labelByTrackerStepName, trackerStepStatus } from 'src/components/TransactionSummary/constants';
import { Box, Typography } from '@mui/material';
import { ActiveTransactionSummaryField } from 'src/components/TransactionSummary/styles';
import moment from 'moment';
import palette from 'src/theme/palette';
import { OldTrackerSectionProps } from './types';

const OldTrackerSection: FC<OldTrackerSectionProps> = ({ step, tracker }) => {
	const trackerStep = tracker[step];
	const label = labelByTrackerStepName[step];

	const statusParams = {
		[trackerStepStatus.notStarted]: { label: 'NOT STARTED', color: '#EEF1F3' },
		[trackerStepStatus.inProgress]: {
			// @ts-ignore
			label: `IN PROGRESS ${trackerStep.date ? `(ETA ${moment(trackerStep.date.toDate()).format('DD/MM/YYYY')})` : ''}`,
			color: 'yellow',
		},
		[trackerStepStatus.pending]: {
			// @ts-ignore
			label: `DELAYED ${trackerStep.date ? `(ETA ${moment(trackerStep.date.toDate()).format('DD/MM/YYYY')})` : ''}`,
			color: 'orange',
		},
		[trackerStepStatus.completed]: {
			// @ts-ignore
			label: `COMPLETED ${trackerStep.date ? `(${moment(trackerStep.date.toDate()).format('DD/MM/YYYY')})` : ''}`,
			color: 'green',
		},
		[trackerStepStatus.error]: { label: `ERROR`, color: 'red' },
	};

	const status = statusParams[trackerStep.status];

	return (
		<Box>
			<ActiveTransactionSummaryField
				name={label}
				sx={{ borderTop: `2px solid ${palette.light.grey[400]}`, background: status.color }}
				titleSx={{
					borderRight: `2px solid ${palette.light.grey[400]}`,
					marginRight: '7px',
					flex: 0.6,
					pr: '10px',
				}}
				childrenSx={{ flex: 0.4 }}
			>
				<Typography variant='h6'>{status.label}</Typography>
			</ActiveTransactionSummaryField>
		</Box>
	);
};

export default OldTrackerSection;
