import { FC } from 'react';
import { useSelector } from 'react-redux';
import { selectTransactionTracker } from 'src/redux/slices/transaction';
import { labelByTrackerStepName, trackerStepStatus } from 'src/components/TransactionSummary/constants';
import { Box, Typography } from '@mui/material';
import { ActiveTransactionSummaryField } from 'src/components/TransactionSummary/styles';
import moment from 'moment';
import palette from 'src/theme/palette';
import { TrackerSectionProps } from './types';
import Task from '../Task';

const TrackerSection: FC<TrackerSectionProps> = ({ step }) => {
	const tracker = useSelector(selectTransactionTracker);

	if (!tracker.data) return null;

	const trackerStep = tracker.data[step];
	const label = labelByTrackerStepName[step];

	const statusParams = {
		[trackerStepStatus.notStarted]: { label: 'NOT STARTED', color: '#EEF1F3' },
		[trackerStepStatus.inProgress]: {
			label: `IN PROGRESS ${trackerStep.date ? `(ETA ${moment(trackerStep.date).format('DD/MM/YYYY')})` : ''}`,
			color: 'yellow',
		},
		[trackerStepStatus.pending]: {
			label: `DELAYED ${trackerStep.date ? `(ETA ${moment(trackerStep.date).format('DD/MM/YYYY')})` : ''}`,
			color: 'orange',
		},
		[trackerStepStatus.completed]: {
			label: `COMPLETED ${trackerStep.date ? `(${moment(trackerStep.date).format('DD/MM/YYYY')})` : ''}`,
			color: 'green',
		},
		[trackerStepStatus.error]: { label: `ERROR`, color: 'red' },
	};

	const status = statusParams[trackerStep.status];

	return (
		<Box>
			<ActiveTransactionSummaryField
				name={label}
				sx={{ borderTop: `2px solid ${palette.light.grey[400]}`, background: status.color }}
				titleSx={{
					borderRight: `2px solid ${palette.light.grey[400]}`,
					marginRight: '7px',
					flex: 0.6,
					pr: '10px',
				}}
				childrenSx={{ flex: 0.4 }}
			>
				<Typography variant='h6'>{status.label}</Typography>
			</ActiveTransactionSummaryField>
			{trackerStep.tasks.map((t) => (
				<Task key={t.id} {...t} />
			))}
		</Box>
	);
};

export default TrackerSection;
