import { FC } from 'react';
import { Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectUser } from 'src/redux/slices/auth';
import { dispatch } from 'src/redux/store';
import { openModal } from 'src/redux/slices/modal';
import modals from 'src/constants/modals';
import { SignButtonProps } from './types';

const SignButton: FC<SignButtonProps> = ({ disabled, task }) => {
	const user = useSelector(selectUser);

	const handleClick = () => {
		if (!user.signature) dispatch(openModal({ name: modals.enterSignature, payload: { task } }));
		else dispatch(openModal({ name: modals.signDeed, payload: { task } }));
	};

	return (
		<Typography
			variant='link'
			onClick={handleClick}
			sx={{
				pointerEvents: disabled ? 'none' : 'auto',
				display: 'inline-flex',
				alignItems: 'center',
				gap: '3px',
			}}
			{...(disabled && { color: 'gray' })}
		>
			[Sign]
		</Typography>
	);
};

export default SignButton;
