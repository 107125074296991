/* eslint-disable no-param-reassign */
import { createAction, createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { and, getDocs, or, query, where } from 'firebase/firestore';

import { AuthErrorMessages } from 'src/api/@';
import Iconify from 'src/components/Iconify';
import { INavConfig } from 'src/components/nav-section/vertical/types';
import { PATH_TRANSACTIONS, PATH_PROPERTIES, PATH_INSTRUMENTS } from 'src/routes/paths';
import { docsToNav } from 'src/utils/propertyHelpers';
import { propertiesCollection, transactionsCollection } from 'src/constants/firestore';
import { bool } from 'src/constants/common';
import { getUserSettings } from 'src/utils/firebase';
import { adminTransactionsFilter, archiveTransactionsFilter } from 'src/utils/transaction';
import { RootState } from '../store';
import { NavSliceState } from '../types/navSlice';
import { selectUser } from './auth';

// ----------------------------------------------------------------------

const initialState: NavSliceState = {
	navbar: [],
	loadingNavbar: true,
};

export const getAdminNav = createAsyncThunk('navBar/getAdminNav', async (_, thunkAPI) => {
	try {
		const { uid } = selectUser(thunkAPI.getState());
		const settings = await getUserSettings(uid);

		const pinnedPropertiesIds = settings?.pinnedProperties.map(({ id }) => id) ?? [];
		const pinnedTransactionsIds = settings?.pinnedTransactions.map(({ id }) => id) ?? [];

		const [{ docs: properties }, { docs: transactions }, { docs: instruments }] = await Promise.all([
			settings?.pinnedProperties.length
				? getDocs(query(propertiesCollection, where('onSale', '==', false)))
				: { docs: [] },
			settings?.pinnedTransactions.length ? getDocs(query(transactionsCollection)) : { docs: [] },
			settings?.pinnedTransactions.length
				? getDocs(query(transactionsCollection, where('isInstrument', '==', true)))
				: { docs: [] },
		]);

		return [
			{
				subheader: 'NAVIGATION',
				items: [{ title: 'Dashboard', path: '/dashboard', icon: <Iconify icon='ph:house' /> }],
			},
			{
				subheader: 'PROPERTIES',
				items: await docsToNav(
					properties.filter((p) => pinnedPropertiesIds.includes(p.id)),
					PATH_PROPERTIES,
				),
			},
			{
				subheader: 'TRANSACTIONS',
				items: await docsToNav(
					transactions.filter((t) => pinnedTransactionsIds.includes(t.id)),
					PATH_TRANSACTIONS,
					'mdi:tag-outline',
					adminTransactionsFilter,
				),
			},
			{
				subheader: 'REMORTGAGES',
				items: await docsToNav(
					instruments.filter((i) => pinnedTransactionsIds.includes(i.id)),
					PATH_INSTRUMENTS,
					'mdi:tag-outline',
					adminTransactionsFilter,
				),
			},
		];
	} catch (error) {
		console.log('error', error);
		return thunkAPI.rejectWithValue({ error });
	}
});

export const getPrincipalNav = createAsyncThunk('navBar/getPrincipalNav', async (_, thunkAPI) => {
	const {
		auth: { user },
	} = thunkAPI.getState() as RootState;

	try {
		if (!user) throw new Error(AuthErrorMessages.noLogin);

		const propertiesQuery = getDocs(
			query(
				propertiesCollection,
				and(or(where('proprietors', 'array-contains', user.ref), where('viewers', 'array-contains', user.ref))),
			),
		);
		const saleQuery = getDocs(
			query(transactionsCollection, where(`parties.${user.uid}.sell.seller.approved`, 'in', bool)),
		);
		const purchaseQuery = getDocs(
			query(
				transactionsCollection,
				or(
					where(`parties.${user.uid}.buy.buyer.approved`, 'in', bool),
					where(`parties.${user.uid}.view.buyer.approved`, 'in', bool),
				),
			),
		);
		const refinancingQuery = getDocs(
			query(
				transactionsCollection,
				and(
					or(
						where(`parties.${user.uid}.buy.buyer.approved`, 'in', bool),
						where(`parties.${user.uid}.view.buyer.approved`, 'in', bool),
						where(`parties.${user.uid}.sell.seller.approved`, 'in', bool),
					),
					where('isInstrument', '==', true),
				),
			),
		);

		const [{ docs: propertyDocs }, { docs: saleDocs }, { docs: purchaseDocs }, { docs: refinancingDocs }] =
			await Promise.all([propertiesQuery, saleQuery, purchaseQuery, refinancingQuery]);
		const transactionPropertiesIds = [...saleDocs, ...purchaseDocs].map(
			(transaction) => transaction.data().property.id,
		);
		const filteredPropertiesDocs = propertyDocs.filter(({ id }) => !transactionPropertiesIds.includes(id));

		const allTransactionsDocs = [...saleDocs, ...purchaseDocs, ...refinancingDocs];

		return [
			{
				subheader: 'NAVIGATION',
				items: [{ title: 'Dashboard', path: '/dashboard', icon: <Iconify icon='ph:house' /> }],
			},
			{
				subheader: 'YOUR PROPERTIES',
				items: await docsToNav(filteredPropertiesDocs, PATH_PROPERTIES, 'codicon:key'),
			},
			{
				subheader: 'YOUR SALES',
				items: await docsToNav(saleDocs, PATH_TRANSACTIONS, 'mdi:tag-outline'),
			},
			{
				subheader: 'YOUR PURCHASES',
				items: await docsToNav(purchaseDocs, PATH_TRANSACTIONS, 'mdi:tag-outline'),
			},
			{
				subheader: 'REMORTGAGES',
				items: await docsToNav(refinancingDocs, PATH_INSTRUMENTS, 'mdi:tag-outline'),
			},
			{
				subheader: 'ARCHIVE',
				items: await docsToNav(allTransactionsDocs, PATH_INSTRUMENTS, 'mdi:tag-outline', archiveTransactionsFilter),
			},
		];
	} catch (error) {
		console.log('error', error);
		return thunkAPI.rejectWithValue({ error });
	}
});

export const getEstateNav = createAsyncThunk('navBar/getEstateNav', async (_, thunkAPI) => {
	const {
		auth: { user },
	} = thunkAPI.getState() as RootState;

	try {
		if (!user) throw new Error(AuthErrorMessages.noLogin);

		const buySideTransactionsQuery = query(
			transactionsCollection,
			or(where(`parties.${user.uid}.buy.agent.approved`, 'in', bool)),
		);
		const sellSideTransactionsQuery = query(
			transactionsCollection,
			or(where(`parties.${user.uid}.sell.agent.approved`, 'in', bool)),
		);
		const refinancingQuery = query(
			transactionsCollection,
			and(
				or(
					where(`parties.${user.uid}.sell.agent.approved`, 'in', bool),
					where(`parties.${user.uid}.buy.agent.approved`, 'in', bool),
				),
				where('isInstrument', '==', true),
			),
		);

		const [{ docs: buySideDocs }, { docs: sellSideDocs }, { docs: refinancingDocs }] = await Promise.all([
			getDocs(buySideTransactionsQuery),
			getDocs(sellSideTransactionsQuery),
			getDocs(refinancingQuery),
		]);

		const allTransactionsDocs = [...buySideDocs, ...sellSideDocs, ...refinancingDocs];

		return [
			{
				subheader: 'NAVIGATION',
				items: [{ title: 'Dashboard', path: '/dashboard', icon: <Iconify icon='ph:house' /> }],
			},
			{
				subheader: 'SALES',
				items: await docsToNav(sellSideDocs, PATH_TRANSACTIONS, 'bx:building-house'),
			},
			{
				subheader: 'PURCHASES',
				items: await docsToNav(buySideDocs, PATH_TRANSACTIONS, 'bx:building-house'),
			},
			{
				subheader: 'REMORTGAGES',
				items: await docsToNav(refinancingDocs, PATH_INSTRUMENTS, 'mdi:tag-outline'),
			},
			{
				subheader: 'ARCHIVE',
				items: await docsToNav(allTransactionsDocs, PATH_INSTRUMENTS, 'mdi:tag-outline', archiveTransactionsFilter),
			},
		];
	} catch (error) {
		console.log('errr', error);
		return thunkAPI.rejectWithValue({ error });
	}
});

export const getSolicitorNav = createAsyncThunk('navBar/getSolicitorNav', async (_, thunkAPI) => {
	const {
		auth: { user },
	} = thunkAPI.getState() as RootState;

	try {
		if (!user) throw new Error(AuthErrorMessages.noLogin);
		const sellSideTransactionsQuery = query(
			transactionsCollection,
			or(where(`parties.${user.uid}.sell.solicitor.approved`, 'in', bool)),
		);

		const buySideTransactionsQuery = query(
			transactionsCollection,
			or(where(`parties.${user.uid}.buy.solicitor.approved`, 'in', bool)),
		);
		const refinancingQuery = query(
			transactionsCollection,
			and(
				or(
					where(`parties.${user.uid}.sell.solicitor.approved`, 'in', bool),
					where(`parties.${user.uid}.buy.solicitor.approved`, 'in', bool),
				),
				where('isInstrument', '==', true),
			),
		);
		const [{ docs: sellSideDocs }, { docs: buySideDocs }, { docs: refinancingDocs }] = await Promise.all([
			getDocs(sellSideTransactionsQuery),
			getDocs(buySideTransactionsQuery),
			getDocs(refinancingQuery),
		]);

		const allTransactionsDocs = [...buySideDocs, ...sellSideDocs, ...refinancingDocs];

		return [
			{
				subheader: 'NAVIGATION',
				items: [{ title: 'Dashboard', path: '/dashboard', icon: <Iconify icon='ph:house' /> }],
			},
			{
				subheader: 'SALES',
				items: await docsToNav(sellSideDocs, PATH_TRANSACTIONS, 'bx:building-house'),
			},
			{
				subheader: 'PURCHASES',
				items: await docsToNav(buySideDocs, PATH_TRANSACTIONS, 'bx:building-house'),
			},
			{
				subheader: 'REMORTGAGES',
				items: await docsToNav(refinancingDocs, PATH_INSTRUMENTS, 'mdi:tag-outline'),
			},
			{
				subheader: 'ARCHIVE',
				items: await docsToNav(allTransactionsDocs, PATH_INSTRUMENTS, 'mdi:tag-outline', archiveTransactionsFilter),
			},
		];
	} catch (error) {
		return thunkAPI.rejectWithValue({ error });
	}
});

export const getTradesPersonNav = createAsyncThunk('navBar/getTradesPersonNav', async (_, thunkAPI) => {
	const {
		auth: { user },
	} = thunkAPI.getState() as RootState;

	try {
		if (!user) throw new Error(AuthErrorMessages.noLogin);

		const buySideTransactionsQuery = query(
			transactionsCollection,
			or(where(`parties.${user.uid}.buy.tradesPerson.approved`, 'in', bool)),
		);
		const sellSideTransactionsQuery = query(
			transactionsCollection,
			or(where(`parties.${user.uid}.sell.tradesPerson.approved`, 'in', bool)),
		);
		const refinancingQuery = query(
			transactionsCollection,
			and(
				or(
					where(`parties.${user.uid}.sell.tradesPerson.approved`, 'in', bool),
					where(`parties.${user.uid}.buy.tradesPerson.approved`, 'in', bool),
				),
				where('isInstrument', '==', true),
			),
		);

		const [{ docs: buySideDocs }, { docs: sellSideDocs }, { docs: refinancingDocs }] = await Promise.all([
			getDocs(buySideTransactionsQuery),
			getDocs(sellSideTransactionsQuery),
			getDocs(refinancingQuery),
		]);

		const allTransactionsDocs = [...buySideDocs, ...sellSideDocs, ...refinancingDocs];

		return [
			{
				subheader: 'NAVIGATION',
				items: [{ title: 'Dashboard', path: '/dashboard', icon: <Iconify icon='ph:house' /> }],
			},
			{
				subheader: 'SALES',
				items: await docsToNav(sellSideDocs, PATH_TRANSACTIONS, 'bx:building-house'),
			},
			{
				subheader: 'PURCHASES',
				items: await docsToNav(buySideDocs, PATH_TRANSACTIONS, 'bx:building-house'),
			},
			{
				subheader: 'REMORTGAGES',
				items: await docsToNav(refinancingDocs, PATH_INSTRUMENTS, 'mdi:tag-outline'),
			},
			{
				subheader: 'ARCHIVE',
				items: await docsToNav(allTransactionsDocs, PATH_INSTRUMENTS, 'mdi:tag-outline', archiveTransactionsFilter),
			},
		];
	} catch (error) {
		return thunkAPI.rejectWithValue({ error });
	}
});

export const getMortgageBrokerNav = createAsyncThunk('navBar/getMortgageBrokerNav', async (_, thunkAPI) => {
	const {
		auth: { user },
	} = thunkAPI.getState() as RootState;

	try {
		if (!user) throw new Error(AuthErrorMessages.noLogin);

		const buySideTransactionsQuery = query(
			transactionsCollection,
			or(where(`parties.${user.uid}.buy.mortgageBroker.approved`, 'in', bool)),
		);
		const sellSideTransactionsQuery = query(
			transactionsCollection,
			or(where(`parties.${user.uid}.sell.mortgageBroker.approved`, 'in', bool)),
		);
		const refinancingQuery = query(
			transactionsCollection,
			and(
				or(
					where(`parties.${user.uid}.sell.mortgageBroker.approved`, 'in', bool),
					where(`parties.${user.uid}.buy.mortgageBroker.approved`, 'in', bool),
				),
				where('isInstrument', '==', true),
			),
		);

		const [{ docs: buySideDocs }, { docs: sellSideDocs }, { docs: refinancingDocs }] = await Promise.all([
			getDocs(buySideTransactionsQuery),
			getDocs(sellSideTransactionsQuery),
			getDocs(refinancingQuery),
		]);

		const allTransactionsDocs = [...buySideDocs, ...sellSideDocs, ...refinancingDocs];

		return [
			{
				subheader: 'NAVIGATION',
				items: [{ title: 'Dashboard', path: '/dashboard', icon: <Iconify icon='ph:house' /> }],
			},
			{
				subheader: 'SALES',
				items: await docsToNav(sellSideDocs, PATH_TRANSACTIONS, 'bx:building-house'),
			},
			{
				subheader: 'PURCHASES',
				items: await docsToNav(buySideDocs, PATH_TRANSACTIONS, 'bx:building-house'),
			},
			{
				subheader: 'REMORTGAGES',
				items: await docsToNav(refinancingDocs, PATH_INSTRUMENTS, 'mdi:tag-outline'),
			},
			{
				subheader: 'ARCHIVE',
				items: await docsToNav(allTransactionsDocs, PATH_INSTRUMENTS, 'mdi:tag-outline', archiveTransactionsFilter),
			},
		];
	} catch (error) {
		return thunkAPI.rejectWithValue({ error });
	}
});

export const getSurveyorNav = createAsyncThunk('navBar/getSurveyorNav', async (_, thunkAPI) => {
	const {
		auth: { user },
	} = thunkAPI.getState() as RootState;

	try {
		if (!user) throw new Error(AuthErrorMessages.noLogin);

		const buySideTransactionsQuery = query(
			transactionsCollection,
			or(where(`parties.${user.uid}.buy.surveyor.approved`, 'in', bool)),
		);
		const sellSideTransactionsQuery = query(
			transactionsCollection,
			or(where(`parties.${user.uid}.sell.surveyor.approved`, 'in', bool)),
		);
		const refinancingQuery = query(
			transactionsCollection,
			and(
				or(
					where(`parties.${user.uid}.sell.surveyor.approved`, 'in', bool),
					where(`parties.${user.uid}.buy.surveyor.approved`, 'in', bool),
				),
				where('isInstrument', '==', true),
			),
		);

		const [{ docs: buySideDocs }, { docs: sellSideDocs }, { docs: refinancingDocs }] = await Promise.all([
			getDocs(buySideTransactionsQuery),
			getDocs(sellSideTransactionsQuery),
			getDocs(refinancingQuery),
		]);

		const allTransactionsDocs = [...buySideDocs, ...sellSideDocs, ...refinancingDocs];

		return [
			{
				subheader: 'NAVIGATION',
				items: [{ title: 'Dashboard', path: '/dashboard', icon: <Iconify icon='ph:house' /> }],
			},
			{
				subheader: 'SALES',
				items: await docsToNav(sellSideDocs, PATH_TRANSACTIONS, 'bx:building-house'),
			},
			{
				subheader: 'PURCHASES',
				items: await docsToNav(buySideDocs, PATH_TRANSACTIONS, 'bx:building-house'),
			},
			{
				subheader: 'REMORTGAGES',
				items: await docsToNav(refinancingDocs, PATH_INSTRUMENTS, 'mdi:tag-outline'),
			},
			{
				subheader: 'ARCHIVE',
				items: await docsToNav(allTransactionsDocs, PATH_INSTRUMENTS, 'mdi:tag-outline', archiveTransactionsFilter),
			},
		];
	} catch (error) {
		return thunkAPI.rejectWithValue({ error });
	}
});

export const clearNavBar = createAction('navBar/clear');

const navSlice = createSlice({
	name: 'navBar',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(getPrincipalNav.fulfilled, (state: NavSliceState, action: PayloadAction<INavConfig[]>) => {
			state.navbar = action.payload;
			state.loadingNavbar = false;
		});

		builder.addCase(getAdminNav.fulfilled, (state: NavSliceState, action: PayloadAction<INavConfig[]>) => {
			state.navbar = action.payload;
			state.loadingNavbar = false;
		});
		builder.addCase(getAdminNav.pending, (state: NavSliceState) => {
			state.loadingNavbar = true;
		});

		builder.addCase(getEstateNav.fulfilled, (state: NavSliceState, action: PayloadAction<INavConfig[]>) => {
			state.navbar = action.payload;
			state.loadingNavbar = false;
		});
		builder.addCase(getEstateNav.pending, (state: NavSliceState) => {
			state.loadingNavbar = true;
		});

		builder.addCase(getTradesPersonNav.fulfilled, (state: NavSliceState, action: PayloadAction<INavConfig[]>) => {
			state.navbar = action.payload;
			state.loadingNavbar = false;
		});
		builder.addCase(getTradesPersonNav.pending, (state: NavSliceState) => {
			state.loadingNavbar = true;
		});

		builder.addCase(getMortgageBrokerNav.fulfilled, (state: NavSliceState, action: PayloadAction<INavConfig[]>) => {
			state.navbar = action.payload;
			state.loadingNavbar = false;
		});
		builder.addCase(getMortgageBrokerNav.pending, (state: NavSliceState) => {
			state.loadingNavbar = true;
		});

		builder.addCase(getSurveyorNav.fulfilled, (state: NavSliceState, action: PayloadAction<INavConfig[]>) => {
			state.navbar = action.payload;
			state.loadingNavbar = false;
		});
		builder.addCase(getSurveyorNav.pending, (state: NavSliceState) => {
			state.loadingNavbar = true;
		});

		builder.addCase(getSolicitorNav.fulfilled, (state: NavSliceState, action: PayloadAction<INavConfig[]>) => {
			state.navbar = action.payload;
			state.loadingNavbar = false;
		});

		builder.addCase(clearNavBar, (state) => {
			state.navbar = [];
		});
	},
});

// Actions
// export const {} = navSlice.actions;

// Selectors
export const selectNavbar = (state) => state.navbar.navbar;

// Reducer
export default navSlice.reducer;
