/* eslint-disable no-param-reassign */
import { sentenceCase } from 'change-case';
import { RoomCategoryType, Rooms, RoomType } from 'src/redux/types';
import { IExtendedQuestionnaireProperty } from 'src/types';

const getRoomTypeByRoomCategory = (category: RoomCategoryType): RoomType | null => {
	switch (category) {
		case 'bathroom':
			return 'bathroom';
		case 'bedroom':
			return 'bedroom';
		case 'living':
			return 'living';
		case 'circulation':
			return 'bathroom';
		case 'outdoors':
			return 'outdoors';
		case 'specialty':
			return 'bathroom';
		case 'storage_and_service':
			return 'bathroom';
		case 'utility':
			return 'utility';
		default:
			return null;
	}
};

export const fittingsAndContents = (propertyQuestionnaire: IExtendedQuestionnaireProperty | null, rooms: Rooms) => {
	const pages = Object.keys(rooms.types)
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		.reduce<any[]>((acc, key) => {
			const type = rooms.types[key];
			const roomType = getRoomTypeByRoomCategory(key as RoomCategoryType);
			const nameAttribute = `${key}_rooms`;
			const countAttribute = `${key}_count`;
			const labelAttribute = `${key}_name`;

			if (!roomType) return acc;

			const getElements = (roomName: string) => {
				const extendedElements = Object.keys(rooms[roomType]).map((k) => {
					const tagbox = rooms[roomType][k];

					const extendedTagbox = structuredClone(tagbox);

					if (roomType === 'living') {
						if (roomName === 'open_plan') {
							extendedTagbox.defaults.push(...rooms.utility[k].defaults);
							extendedTagbox.options.push(...rooms.utility[k].options);
						}
						if (roomName === 'bedsit') {
							extendedTagbox.defaults.push(...rooms.bedroom[k].defaults);
							extendedTagbox.options.push(...rooms.bedroom[k].options);
						}
						if (roomName === 'studio') {
							extendedTagbox.defaults.push(...rooms.utility[k].defaults, ...rooms.bedroom[k].defaults);
							extendedTagbox.options.push(...rooms.utility[k].options, ...rooms.bedroom[k].options);
						}
					}

					return {
						type: 'tagbox',
						name: k,
						title: extendedTagbox.name,
						choices: Array.from(new Set(extendedTagbox.options)),
						defaultValue: Array.from(new Set(extendedTagbox.defaults)),
						order: extendedTagbox.order ?? 1,
						choicesOrder: 'asc',
						showOtherItem: true,
						hideSelectedItems: true,
						closeOnSelect: true,
						overridePrefilling: true,
					};
				});

				if (roomType === 'living' && (roomName === 'open_plan' || roomName === 'studio')) {
					Object.keys(rooms.utility).forEach((k) => {
						const isExistingElement = extendedElements.some((e) => e.name === k);

						if (isExistingElement) return;

						const tagbox = rooms.utility[k];

						extendedElements.push({
							type: 'tagbox',
							name: k,
							title: tagbox.name,
							choices: Array.from(new Set(tagbox.options)),
							defaultValue: Array.from(new Set(tagbox.defaults)),
							order: tagbox.order ?? 1,
							choicesOrder: 'asc',
							showOtherItem: true,
							hideSelectedItems: true,
							closeOnSelect: true,
							overridePrefilling: true,
						});
					});
				}

				return extendedElements.sort((a, b) => a.order - b.order);
			};

			const groupedRooms = propertyQuestionnaire?.property_details?.rooms?.[key] ?? [];

			const groupedRoomsCount = groupedRooms.reduce((roomsCountAcc, room) => {
				const count = room[countAttribute] ?? 0;

				roomsCountAcc += count;

				return roomsCountAcc;
			}, 0);

			let totalIndex = 1;

			acc.push({
				name: key,
				title: type.name,
				order: type.order ?? 1,
				elements: groupedRooms.reduce(
					(roomsAcc, room) => {
						const count = room[countAttribute] ?? 1;
						const roomName = room[nameAttribute];
						const roomLabel = room[labelAttribute];

						if (!roomName) return roomsAcc;

						Array.from({ length: count }, (_, index) => {
							const name = `${key}_${totalIndex}`;
							const title = `${sentenceCase(room[nameAttribute] ?? '')}${count > 1 ? ` ${index + 1}` : ''}`;
							const elements = getElements(roomName);

							roomsAcc.push({
								type: 'panel',
								showQuestionNumbers: 'off',
								name,
								title: roomLabel?.toUpperCase() ?? title.toUpperCase(),
								elements: [
									...elements.map((e) => ({ ...e, name: `${name}_${e.name}` })),
									{
										type: 'text',
										name: `${name}_type`,
										title: 'Room type *',
										visible: false,
										isRequired: true,
										skipPrefill: true,
										defaultValue: [roomName],
									},
								],
								noEntriesText: '',
							});

							totalIndex++;

							return null;
						});

						return roomsAcc;
					},
					[
						{
							type: 'text',
							inputType: 'number',
							name: 'rooms_count',
							title: 'Rooms count*',
							visible: false,
							isRequired: true,
							skipPrefill: true,
							defaultValue: [groupedRoomsCount],
						},
					],
				),
			});

			return acc;
		}, [])
		.sort((a, b) => a.order - b.order);

	return {
		title: 'Fixtures, Fittings, Contents',
		description: 'Please list what stays in each room after the sale. Leave any sections that don`t apply blank.',
		logoPosition: 'right',
		pages: [
			...pages,
			{
				name: 'other',
				elements: [
					{
						type: 'tagbox',
						name: 'stock_of_fuel',
						title: 'Stock of fuel',
						description: 'Are any of the following selling with the property?',
						hideNumber: true,
						choices: ['Oil', 'Wood', 'Liquefied Petroleum Gas (LPG)'],
						choicesOrder: 'asc',
						showOtherItem: true,
					},
					{
						type: 'boolean',
						name: 'appliances_working_yn',
						title:
							'If any electrical or gas appliances are included in the sale, please confirm that they are to your knowledge in good working order. *',
						hideNumber: true,
						defaultValue: 'true',
						isRequired: true,
						labelTrue: 'Confirmed',
						labelFalse: 'Not confirmed',
					},
				],
				title: 'Other items',
			},
			{
				name: 'items_for_sale',
				elements: [
					{
						type: 'boolean',
						name: 'items_for_sale_yn',
						title: 'Would you like to sell any of the contents with the property? *',
						isRequired: true,
					},
					{
						type: 'html',
						name: 'items_for_sale_note',
						visible: false,
						visibleIf: '{items_for_sale_yn} = true',
						html: "That's great! You'll find a <b>Sold items</b> card waiting for you in the <b>Seller's questions</b> tab. There, you can list and price any items you wish to sell with the property.",
					},
				],
				title: 'Selling the contents',
				description:
					"You have the option to try and sell any of the contents to the buyer as part of the sale. However, it's important to remember that adding items for sale can cause delays, overpricing may turn off potential buyers, and offering too many items can be overwhelming. Keep the list reasonable to avoid confusion and ensure a successful sale. Ultimately, it's up to the buyer to decide whether or not to accept your offer.",
			},
		],
		showTitle: false,
		showCompletedPage: false,
		completeText: 'Submit',
	};
};
