import { ChangeEvent, FC, MouseEvent, useState } from 'react';
import { useTask } from 'src/components/GeneralTab/components/TasksSection/components/Task/hooks/useTask';
import palette from 'src/theme/palette';
import { Box, Checkbox, Fade, IconButton, MenuItem, TextField, Typography } from '@mui/material';
import MenuPopover from 'src/components/MenuPopover';
import Iconify from 'src/components/Iconify';
import { taskStatus } from 'src/constants/tasks';
import { getTaskRef } from 'src/utils/firebase';
import { updateDoc } from 'firebase/firestore';
import { dispatch } from 'src/redux/store';
import { getTransactionTrackerThunk, selectTransactionOverview } from 'src/redux/slices/transaction';
import { DatePicker } from '@mui/x-date-pickers';
import { useSelector } from 'react-redux';
import { openModal } from 'src/redux/slices/modal';
import modals from 'src/constants/modals';
import { TaskProps } from './types';
import { SummaryFieldWrapper } from '../../../SummaryField/styled';

const Task: FC<TaskProps> = (task) => {
	const transaction = useSelector(selectTransactionOverview);
	const { description, statement } = useTask(task);
	const [menuAnchor, setMenuAnchor] = useState<Element | null>(null);
	const [date, setDate] = useState<Date | null>(
		task.completedAt ? new Date(task.completedAt) : task.dueDate ? new Date(task.dueDate) : null,
	);

	const taskRef = getTaskRef(task.id);

	const handleDelete = async () => {
		setMenuAnchor(null);
		await updateDoc(taskRef, { deleted: true });
		dispatch(getTransactionTrackerThunk({ id: transaction.id, withLoading: true }));
	};
	const handleMove = () => {
		setMenuAnchor(null);
		dispatch(openModal({ name: modals.moveTask, payload: task }));
	};
	const handleCheckboxChange = async (e: ChangeEvent<HTMLInputElement>) => {
		const isChecked = e.target.checked;
		const completedAt = isChecked ? new Date() : null;

		await updateDoc<unknown>(taskRef, {
			status: isChecked ? taskStatus.done : taskStatus.toDo,
			completedAt,
		});
		setDate(completedAt);
		dispatch(getTransactionTrackerThunk({ id: transaction.id, withLoading: true }));
	};
	const handleMenuOpen = (e: MouseEvent<HTMLButtonElement>) => setMenuAnchor(e.currentTarget);
	const handleDateChange = async (newDate: Date | null) => {
		const dateField = task.category === 'admin' ? 'completedAt' : 'dueDate';

		setDate(newDate);
		await updateDoc(taskRef, { [dateField]: newDate });
		dispatch(getTransactionTrackerThunk({ id: transaction.id }));
	};

	const mark =
		task.status === taskStatus.done
			? '🟢'
			: task.dueDate && new Date(task.dueDate) <= new Date()
			? '🟠'
			: task.status === taskStatus.inProgress
			? '🟡'
			: '⚪️';

	const title = (
		<Box display='flex' alignItems='center' gap='10px'>
			{task.category === 'admin' ? (
				<Box>
					<Checkbox defaultChecked={task.status === taskStatus.done} onChange={handleCheckboxChange} />
				</Box>
			) : (
				<Box width='38px' />
			)}
			<Box>
				{mark} {description ?? statement}
			</Box>
		</Box>
	);

	const children = date ? (
		<DatePicker
			value={date}
			onChange={handleDateChange}
			renderInput={(props) => <TextField fullWidth {...props} />}
			inputFormat='dd/MM/yyyy'
		/>
	) : null;

	return (
		<SummaryFieldWrapper
			display='flex'
			alignItems='stretch'
			justifyContent='space-between'
			px='10px'
			sx={{ borderBottom: `2px solid ${palette.light.grey[400]}` }}
		>
			<Box display='flex' alignItems='center' flex={0.6} py='10px' pr='10px'>
				<Typography variant='h6'>{title}</Typography>
			</Box>
			<Box
				display='flex'
				flexDirection='row'
				justifyContent='space-between'
				flex={0.4}
				borderLeft={`2px solid ${palette.light.grey[400]}`}
				pl='7px'
			>
				<Box display='flex' alignItems='center' py='7px'>
					{children}
				</Box>
				<Box display='flex' alignItems='center' borderLeft={`2px solid ${palette.light.grey[400]}`} pl='7px'>
					<IconButton
						id='settings'
						aria-controls={menuAnchor ? 'fade-menu' : undefined}
						aria-haspopup='true'
						aria-expanded={menuAnchor ? 'true' : undefined}
						onClick={handleMenuOpen}
					>
						<Iconify icon='bi:three-dots-vertical' width={20} height={20} />
					</IconButton>
					<MenuPopover
						id='fade-menu'
						anchorEl={menuAnchor}
						transformOrigin={{ vertical: 'top', horizontal: 'right' }}
						anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
						open={!!menuAnchor}
						onClose={() => setMenuAnchor(null)}
						TransitionComponent={Fade}
					>
						<MenuItem onClick={handleMove}>Move</MenuItem>
						<MenuItem onClick={handleDelete}>Delete</MenuItem>
					</MenuPopover>
				</Box>
			</Box>
		</SummaryFieldWrapper>
	);
};

export default Task;
