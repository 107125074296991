import { Rooms } from 'src/redux/types';
import { IQuestionnaireProperty, QuestionnaireStatus } from 'src/types';

export const calculateTotalRooms = (propertyQuestionnaire: IQuestionnaireProperty, rooms: Rooms, key?: string) => {
	const arePropertyDetailsCompleted = propertyQuestionnaire.property_details.status === QuestionnaireStatus.DONE;

	if (!arePropertyDetailsCompleted) return null;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	let roomsToCount: any[] = [];

	if (key) {
		roomsToCount = propertyQuestionnaire.property_details.rooms?.[key] ?? [];
	} else {
		roomsToCount = Object.keys(rooms.types).reduce((acc, roomKey) => {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			acc.push(...(propertyQuestionnaire.property_details.rooms?.[roomKey] ?? []).map((r) => ({ ...r, key: roomKey })));

			return acc;
		}, []);
	}

	return (
		roomsToCount?.reduce((acc, room) => {
			const count = room[`${room.key ?? key}_count`];

			if (!count) return acc;

			// eslint-disable-next-line no-param-reassign
			acc += count;

			return acc;
		}, 0) ?? null
	);
};
