/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box } from '@mui/material';
import { FC, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import Modal from 'src/components/customModal';
import { getPropertyQuestionnaire, selectPropertyQuestionnaire } from 'src/redux/slices/propertyQuestionnaire';
import { getTransactionQuestionnaire, selectTransactionQuestionnaire } from 'src/redux/slices/transactionQuestionnaire';
import { RootState, dispatch, useSelector } from 'src/redux/store';
import { QuestionnaireStatus, QuestionnaireTypeEnum } from 'src/types';
import { handleCloseModal } from 'src/utils/propertyHelpers';
import { Model } from 'survey-core';
import { Survey } from 'survey-react-ui';
import theme from 'survey-core/themes/borderless-light';
import { QuestionnaireRestriction, selectQuestionnairePermissions } from 'src/redux/slices/permissions';
import useLoading from 'src/hooks/useLoading';
import { SubtitleStyled } from 'src/pages/transactions/modals/style';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { selectUser } from 'src/redux/slices/auth';
import { QuestionnaireTransactionEnum } from 'src/utils/types';
import { selectModal } from 'src/redux/slices/modal';
import { ProgressBar, ProgressBarWrapper, TypoTitle } from './styles';
import { IQuestionnarieModal } from './types';
import Progress from '../Progress';
import { isQuestionnaireEditable } from '../cards/utils';
import 'survey-core/defaultV2.min.css';

const QuestionnarieModal: FC<IQuestionnarieModal> = ({
	questionnaireType,
	surveyJson,
	questionnaireKey,
	onSubmit,
	onPageChanged,
	title,
	onComplete,
	status,
	trigger,
	submittingError,
	isGiftorFinance,
	modalSx = {},
	initialCurrentPage = 0,
}) => {
	const questionnairePermissions = useSelector(selectQuestionnairePermissions);
	const user = useSelector(selectUser);
	const { modalName, isOpened } = useSelector(selectModal);
	const [survey, setSurvey] = useState<Model | null>(null);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const { isLoading, setIsLoading } = useLoading();
	const { enqueueSnackbar } = useSnackbar();
	const [currentPage, setCurrentPage] = useState<null | number>(initialCurrentPage);
	const [pageTitle, setPageTitle] = useState('');
	const { id = '' } = useParams();
	const { propertyId, transactionId } = useSelector((state: RootState) => ({
		propertyId: selectPropertyQuestionnaire(state)?.property.id,
		transactionId: selectTransactionQuestionnaire(state)?.transaction.id,
	}));

	const isModalOpened = modalName === questionnaireKey && isOpened;
	const isEditable = useMemo(() => {
		if (user.isAdmin) return true;

		if (status === QuestionnaireStatus.DONE) return false;

		if (questionnaireKey === QuestionnaireTransactionEnum.personal_details) return true;

		if (isGiftorFinance) {
			return isQuestionnaireEditable(questionnairePermissions.transaction.buyer_questions as QuestionnaireRestriction);
		}

		if (questionnaireType === QuestionnaireTypeEnum.TRANSACTION) {
			return isQuestionnaireEditable(questionnairePermissions.transaction[questionnaireKey]);
		}

		return isQuestionnaireEditable(questionnairePermissions.property[questionnaireKey]);
	}, [questionnairePermissions, questionnaireType, questionnaireKey, user.isAdmin]);

	const handleClose = async () => {
		if (survey && !isSubmitting && isEditable) await onSubmit(survey);

		handleCloseModal();

		if (questionnaireType === QuestionnaireTypeEnum.PROPERTY) {
			await dispatch(getPropertyQuestionnaire({ id: propertyId ?? id }));
		}
		if (questionnaireType === QuestionnaireTypeEnum.TRANSACTION) {
			await dispatch(getTransactionQuestionnaire({ id: transactionId ?? id }));
		}
	};

	const handleSubmit = async (data: any) => {
		setIsLoading(true);
		await onSubmit(data);

		setTimeout(() => {
			setIsLoading(false);
			setIsSubmitting(true);
		}, 300);
	};

	const handleCancel =
		(shouldCloseModal = false) =>
		async () => {
			setIsSubmitting(false);
			survey?.clear(false, false);

			if (shouldCloseModal) handleCloseModal();
		};
	const handleConfirm = async () => {
		setIsLoading(true);

		await onComplete();
		await handleClose();

		enqueueSnackbar('Answers updated successfully!');
		setIsLoading(false);
	};

	useEffect(() => {
		const model = new Model(surveyJson);
		model.mode = isEditable ? 'edit' : 'display';
		model.showPageTitles = true;
		model.clearInvisibleValues = 'onHidden';
		model.requiredText = '';
		model.focusFirstQuestionAutomatic = false;
		model.showQuestionNumbers = false;
		model.showCompletedPage = false;
		model.completeText = 'Submit';
		model.showPageNumbers = false;
		model.showProgressBar = 'off';
		model.applyTheme(theme);
		model.currentPage = currentPage;
		model.validationAllowSwitchPages = true;
		model.validationAllowComplete = false;

		model.onComplete.add(handleSubmit);

		model.onCurrentPageChanging.add(async (_, options) => {
			setPageTitle(options.newCurrentPage.title);
			setCurrentPage(options.newCurrentPage.visibleIndex);
		});

		setSurvey(model);

		setPageTitle(model.currentPage?.title);
	}, [isEditable, trigger]);

	useEffect(() => {
		if (survey) {
			if (isEditable) {
				survey.onCurrentPageChanged.add((...params) => {
					if (!isModalOpened) return;

					onPageChanged(currentPage, false)(...params);
				});
			}

			survey.currentPage = currentPage;
		}

		return () => {
			if (survey) survey.onCurrentPageChanged.clear();
		};
	}, [onPageChanged, currentPage, isEditable, survey, isModalOpened]);

	return (
		<Modal
			name={questionnaireKey}
			onClose={handleClose}
			cardSx={{
				background: isSubmitting ? 'white' : '#E7F0FF',
				maxWidth: isSubmitting ? '600px' : '812px',
				maxHeight: '90vh',
				overflowY: 'auto',
				'&::-webkit-scrollbar': { display: 'none' },
				...modalSx,
				position: 'relative',
			}}
		>
			{isSubmitting ? (
				submittingError ? (
					<>
						<TypoTitle>{submittingError.title}</TypoTitle>
						<SubtitleStyled mt='10px' sx={{ mb: '15px' }}>
							{submittingError.children}
						</SubtitleStyled>
						<Box display='flex' gap='15px' justifyContent='flex-end'>
							{submittingError.handleCancelClick ? (
								<LoadingButton
									sx={{ fontSize: 16 }}
									onClick={() => {
										survey?.clear(false, false);
										submittingError.handleCancelClick?.({
											setCurrentPage,
											setIsSubmitting,
											handleClose,
											handleConfirm,
										});
									}}
									variant='outlined'
									loading={isLoading}
								>
									Cancel
								</LoadingButton>
							) : null}
							<LoadingButton
								onClick={() => {
									if (submittingError.handleContinueClick) {
										survey?.clear(false, false);
										submittingError.handleContinueClick({
											setCurrentPage,
											setIsSubmitting,
											handleClose,
											handleConfirm,
										});
									} else {
										handleCancel(true);
									}
								}}
								variant='contained'
								loading={isLoading}
							>
								Continue
							</LoadingButton>
						</Box>
					</>
				) : (
					<>
						<TypoTitle>LAST CHANCE TO REVIEW</TypoTitle>
						<SubtitleStyled mt='10px' sx={{ mb: '15px' }}>
							Please review all your answers carefully before submitting.
							<br />
							Once submitted, your responses will be locked and cannot be modified.
						</SubtitleStyled>
						<Box display='flex' gap='15px' justifyContent='flex-end'>
							<LoadingButton onClick={handleCancel()} variant='outlined' size='large' loading={isLoading}>
								Cancel
							</LoadingButton>
							<LoadingButton onClick={handleConfirm} variant='contained' size='large' loading={isLoading}>
								Submit
							</LoadingButton>
						</Box>
					</>
				)
			) : (
				<>
					<TypoTitle>
						{title ?? questionnaireKey.split('_').join(' ').toUpperCase()} / {pageTitle}
					</TypoTitle>
					<Box position='absolute' right={165} top={32}>
						<LoadingButton
							variant='contained'
							sx={{ fontSize: '14px', position: 'fixed', zIndex: 999 }}
							onClick={handleClose}
						>
							Save & Exit
						</LoadingButton>
					</Box>
					<ProgressBarWrapper>
						<ProgressBar currentPage={currentPage ?? 0} pageCount={survey ? survey.visiblePageCount : 0} />
					</ProgressBarWrapper>
					{isLoading ? (
						<Box py='60px'>
							<Progress />
						</Box>
					) : (
						<>{survey && <Survey model={survey} />}</>
					)}
				</>
			)}
		</Modal>
	);
};
export default QuestionnarieModal;
