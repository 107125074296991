import { Box, Button, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Progress from 'src/components/Progress';
import {
	getTransactionOverviewThunk,
	getTransactionSummaryThunk,
	getTransactionTrackerThunk,
	selectTransactionOverview,
	selectTransactionSummary,
} from 'src/redux/slices/transaction';
import { dispatch } from 'src/redux/store';
import { useSnackbar } from 'notistack';
import { SetValueConfig, useForm } from 'react-hook-form';
import { calculatePriceWithVAT, formatDate, formatPrice, formatUsers } from 'src/utils/transaction/summary';
import { openModal } from 'src/redux/slices/modal';
import modals from 'src/constants/modals';
import { ITransactionSummary } from 'src/redux/types';
import { PATH_PROPERTIES } from 'src/routes/paths';
import { Link } from 'react-router-dom';
import { labelByPropertyTitleTenure } from 'src/constants/title';
import SummaryField from './components/SummaryField';
import { ActiveTransactionSummaryField, TransactionSummaryTable } from './styles';
import { FormProvider, RHFEditor } from '../hook-form';
import { getFormattedFormData, updateTransactionSummary } from './utils';
import VATField from './components/VATField';
import Iconify from '../Iconify';
import GenerateDocumentModal from './components/GenerateDocumentModal';
import GetOfficialCopiesModal from './components/GetOfficialCopiesModal';
import AvailableDocumentsModal from './components/AvailableDocumentsModal';
import { formatInputValueToPrice } from './components/VATField/utils';
import PaymentField from './components/PaymentField';
import Tracker from './components/Tracker';
import MoveTaskModal from './components/Tracker/components/MoveTaskModal';
import OldTracker from './components/OldTracker';

const setValueOptions: SetValueConfig = { shouldDirty: true };

const TransactionSummary = () => {
	const { enqueueSnackbar } = useSnackbar();
	const form = useForm<ITransactionSummary>();
	const transaction = useSelector(selectTransactionOverview);
	const summary = useSelector(selectTransactionSummary);

	const sellSide = form.watch('sellSide');
	const buySide = form.watch('buySide');

	useEffect(() => {
		dispatch(getTransactionSummaryThunk({ id: transaction.id }));
		dispatch(getTransactionTrackerThunk({ id: transaction.id }));
	}, [transaction.id]);

	useEffect(() => {
		if (summary.data) form.reset(getFormattedFormData(summary.data));
	}, [summary]);

	useEffect(() => {
		const legalFee2 = (sellSide?.quote?.withoutVat || 0) - (sellSide?.legalFee?.withoutVat || 0);

		const formattedValue = formatInputValueToPrice(legalFee2.toString());

		form.setValue('sellSide.legalFee2.withoutVat', +formattedValue || 0, setValueOptions);
		form.setValue('sellSide.legalFee2.withVat', calculatePriceWithVAT(+formattedValue) || 0, setValueOptions);
	}, [sellSide?.legalFee?.withoutVat, sellSide?.quote?.withoutVat]);

	useEffect(() => {
		const legalFee2 = (buySide?.quote?.withoutVat || 0) - (buySide?.legalFee?.withoutVat || 0);

		const formattedValue = formatInputValueToPrice(legalFee2.toString());

		form.setValue('buySide.legalFee2.withoutVat', +formattedValue || 0, setValueOptions);
		form.setValue('buySide.legalFee2.withVat', calculatePriceWithVAT(+formattedValue) || 0, setValueOptions);
	}, [buySide?.legalFee?.withoutVat, buySide?.quote?.withoutVat]);

	if (!summary.isInitialized || !summary.data) {
		return (
			<Box width='100%' height='80%'>
				<Progress sx={{ height: '100%' }} />
			</Box>
		);
	}

	const handleSubmit = async (data: ITransactionSummary) => {
		try {
			await updateTransactionSummary(data, transaction, form);

			await Promise.all([
				dispatch(getTransactionOverviewThunk({ id: transaction.id })),
				dispatch(getTransactionSummaryThunk({ id: transaction.id })),
			]);

			enqueueSnackbar('Updated successfully!');
		} catch (e) {
			console.error(e);

			if (e instanceof Error) {
				enqueueSnackbar(e.message, { variant: 'error' });

				return;
			}

			enqueueSnackbar('Something went wrong', { variant: 'error' });
		}
	};

	const handleGenerateDocument = () => dispatch(openModal({ name: modals.generateDocument }));
	const handleGetOfficialCopies = () => dispatch(openModal({ name: modals.getOfficialCopies }));
	const handleAssignTitle = () => dispatch(openModal({ name: modals.assignTitle }));

	return (
		<FormProvider methods={form} onSubmit={form.handleSubmit(handleSubmit)}>
			<Box display='flex' gap='15px' alignItems='center'>
				<Typography variant='h3' p={'25px'}>
					Transaction Summary
				</Typography>
				<Button variant='contained' disabled={form.formState.isSubmitting || !form.formState.isDirty} type='submit'>
					Save changes
				</Button>
				<Button variant='contained' onClick={handleAssignTitle}>
					<Iconify icon='material-symbols:title' fontSize={20} mr='5px' />
					Assign title
				</Button>
				<Button variant='contained' onClick={handleGetOfficialCopies}>
					<Iconify icon='teenyicons:documents-outline' fontSize={20} mr='5px' />
					Get Official Copies
				</Button>
				<Button variant='contained' onClick={handleGenerateDocument}>
					<Iconify icon='material-symbols:add-circle-outline-rounded' fontSize={20} mr='5px' />
					Generate document
				</Button>
			</Box>

			<Box p='0 30px' display='flex' flexDirection='row' gap='30px' flexWrap='wrap'>
				<TransactionSummaryTable title='Transaction'>
					<SummaryField name='Title' withBorder={false}>
						{summary.data.general.title ? (
							`${summary.data.general.title.title} (${labelByPropertyTitleTenure[summary.data.general.title.tenure]})`
						) : (
							<Typography color='red' fontWeight={600}>
								UNASSIGNED
							</Typography>
						)}
					</SummaryField>
					<SummaryField name='Edition'>
						{summary.data.general.editionId ?? (
							<Typography color='red' fontWeight={600}>
								UNASSIGNED
							</Typography>
						)}
					</SummaryField>
					<SummaryField name='UPRN'>
						<Link to={PATH_PROPERTIES.view(summary.data.general.propertyId)}>{summary.data.general.uprn}</Link>
					</SummaryField>
					<SummaryField name='Name'>{summary.data.general.displayName}</SummaryField>
					<SummaryField name='Postcode'>{summary.data.general.postcode}</SummaryField>
					<SummaryField name='Ask Price'>
						{summary.data.general.askOffer?.price ? (
							formatPrice(summary.data.general.askOffer?.price)
						) : (
							<Typography color='red' fontWeight={600}>
								PENDING
							</Typography>
						)}
					</SummaryField>
					<SummaryField name='Agreed Price'>
						{summary.data.general.agreedOffer?.price ? (
							formatPrice(summary.data.general.agreedOffer?.price)
						) : (
							<Typography color='red' fontWeight={600}>
								PENDING
							</Typography>
						)}
					</SummaryField>
					{/* <SummaryField name='Next Action'>
						<RHFEditor name='general.nextAction' simple id='next-action' />
					</SummaryField> */}
					<SummaryField name='Notes'>
						<RHFEditor name='general.notes' simple id='notes' />
					</SummaryField>
					<SummaryField name='Title Notes'>
						<RHFEditor name='general.titleNotes' simple id='title-notes' />
					</SummaryField>
					<SummaryField name='Instruction Date'>{formatDate(summary.data.general.instructionDate)}</SummaryField>
					<SummaryField name='Last Updated On'>
						{formatDate(summary.data.general.latestActionDate, 'YYYY-MM-DD hh:mm A')}
					</SummaryField>
					<SummaryField name='Our Margin'>
						£ {summary.data.general.margin} + VAT = £ {calculatePriceWithVAT(summary.data.general.margin)}
					</SummaryField>
				</TransactionSummaryTable>
				<TransactionSummaryTable title='Sell Side' withBorder>
					<ActiveTransactionSummaryField name='PARTICIPANTS' />
					<SummaryField name='Seller(s)'>{formatUsers(summary.data.sellSide.sellers)}</SummaryField>
					<SummaryField name='Agent(s)'>{formatUsers(summary.data.sellSide.agents)}</SummaryField>
					<SummaryField name='Solicitor(s)'>{formatUsers(summary.data.sellSide.solicitors)}</SummaryField>
					<ActiveTransactionSummaryField name='QUOTE (paid by Client)' titleSx={{ whiteSpace: 'nowrap' }} />
					<SummaryField name='Total'>
						<VATField name='sellSide.quote' />
					</SummaryField>
					<SummaryField name='Legal I'>
						<VATField name='sellSide.legalFee' />
					</SummaryField>
					<SummaryField name='Legal II'>
						<VATField name='sellSide.legalFee2' disabled />
					</SummaryField>
					<SummaryField name='Searches'>
						<VATField name='sellSide.searches' />
					</SummaryField>
					<ActiveTransactionSummaryField name='EXPENSES (paid by Conveyo)' titleSx={{ whiteSpace: 'nowrap' }} />
					<SummaryField name='Referral Fee'>
						<VATField name='sellSide.referralFee' />
					</SummaryField>
					<SummaryField name='Official Copies'>
						<VATField name='sellSide.officialCopies' vat={1} />
					</SummaryField>
					<SummaryField name='Identity Checks'>
						<VATField name='sellSide.identityChecks' />
					</SummaryField>
					<SummaryField name='Search Costs'>
						<VATField name='sellSide.searchesWSP' />
					</SummaryField>
					<ActiveTransactionSummaryField name='PAYMENTS (paid by Client)' titleSx={{ whiteSpace: 'nowrap' }} />
					<SummaryField name='Searches Payment'>
						{sellSide?.payment ? <PaymentField {...sellSide.payment} /> : '-'}
					</SummaryField>
					<SummaryField name='Legal II Payment'>
						{sellSide?.exchangePayment ? <PaymentField {...sellSide.exchangePayment} /> : '-'}
					</SummaryField>
					{/* <ActiveTransactionSummaryField name='Agency Rate'>
						<VATField
							name='sellSide.agencyRate'
							inputProps={{ endAdornment: <Iconify icon='ic:round-percent' fontSize={23} ml='5px' /> }}
						/>
					</ActiveTransactionSummaryField>
					<ActiveTransactionSummaryField name='Agency Fee'>
						<VATField name='sellSide.agencyFee' />
					</ActiveTransactionSummaryField> */}
				</TransactionSummaryTable>
				{!transaction.isInstrument && (
					<TransactionSummaryTable title='Buy Side'>
						<ActiveTransactionSummaryField name='PARTICIPANTS' />
						<SummaryField name='Buyer(s)'>{formatUsers(summary.data.buySide.buyers)}</SummaryField>
						<SummaryField name='Agent(s)'>{formatUsers(summary.data.buySide.agents)}</SummaryField>
						<SummaryField name='Solicitor(s)'>{formatUsers(summary.data.buySide.solicitors)}</SummaryField>
						<ActiveTransactionSummaryField name='QUOTE (paid by Client)' titleSx={{ whiteSpace: 'nowrap' }} />
						<SummaryField name='Total'>
							<VATField name='buySide.quote' />
						</SummaryField>
						<SummaryField name='Legal I'>
							<VATField name='buySide.legalFee' />
						</SummaryField>
						<SummaryField name='Legal II'>
							<VATField name='buySide.legalFee2' disabled />
						</SummaryField>
						<SummaryField name='Searches'>
							<VATField name='buySide.searches' />
						</SummaryField>
						<ActiveTransactionSummaryField name='EXPENSES (paid by Conveyo)' titleSx={{ whiteSpace: 'nowrap' }} />
						<SummaryField name='Referral Fee'>
							<VATField name='buySide.referralFee' />
						</SummaryField>
						<SummaryField name='Official Copies'>
							<VATField name='buySide.officialCopies' vat={1} />
						</SummaryField>
						<SummaryField name='Identity Checks'>
							<VATField name='buySide.identityChecks' />
						</SummaryField>
						<SummaryField name='Search Costs'>
							<VATField name='buySide.searchesWSP' />
						</SummaryField>
						<ActiveTransactionSummaryField name='PAYMENTS (paid by Client)' titleSx={{ whiteSpace: 'nowrap' }} />
						<SummaryField name='Searches Payment'>
							{buySide?.payment ? <PaymentField {...buySide.payment} /> : '-'}
						</SummaryField>
						<SummaryField name='Legal II Payment'>
							{buySide?.exchangePayment ? <PaymentField {...buySide.exchangePayment} /> : '-'}
						</SummaryField>
						{/* <ActiveTransactionSummaryField name='Agency Rate'>
							<VATField
								name='buySide.agencyRate'
								inputProps={{ endAdornment: <Iconify icon='ic:round-percent' fontSize={23} ml='5px' /> }}
							/>
						</ActiveTransactionSummaryField>
						<ActiveTransactionSummaryField name='Agency Fee'>
							<VATField name='buySide.agencyFee' />
						</ActiveTransactionSummaryField> */}
					</TransactionSummaryTable>
				)}
				<Tracker />
				<OldTracker />
			</Box>
			<GenerateDocumentModal />
			<GetOfficialCopiesModal />
			<AvailableDocumentsModal />
			<MoveTaskModal />
		</FormProvider>
	);
};

export default TransactionSummary;
